export enum WebSocketEvent {
  RECEIVE_NEW_NOTIFICATION = 'RECEIVE_NEW_NOTIFICATION',
  EDIT_MESSAGE = 'EDIT_MESSAGE',
  REMOVE_MESSAGE = 'REMOVE_MESSAGE',
  PIN_MESSAGE = 'PIN_MESSAGE',
  MARK_AS_READ = 'MARK_AS_READ',
  RECEIVE_NEW_MESSAGE = 'RECEIVE_NEW_MESSAGE',
  BLOCK_USER = 'BLOCK_USER',
  VIDEO_OFFER = 'video-offer',
  VIDEO_ANSWER = 'video-answer',
  NEW_ICE_CANDIDATE = 'new-ice-candidate',
  END_CALL = 'end-call',
  RECEIVE_CALL = 'RECEIVE_CALL',
  ACCEPT_CALL = 'ACCEPT_CALL',
  DENY_CALL = 'DENY_CALL',
  CANCEL_CALL = 'CANCEL_CALL',
}
